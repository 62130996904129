@import "variables";

@import "theme/theme";

@import "~select2/src/scss/core";

@import '../../node_modules/mc-datepicker/dist/mc-calendar.min.css';

.card-header .card-title {
    margin-bottom: 0;
    color: $primary;
    font-weight: bold;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--theme-light);
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
}

input[type=checkbox]:checked {
    accent-color: #249f32;
}